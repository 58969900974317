jQuery(document).ready(function ($) {
  var mobileWidth = 768,
      $overlay = $('<div class="overlay"></div>'),
      $body = $('body'),
      $navbar = $('.js-navbar'),
      $navbarToggle = $('.js-navbar-toggle');

  $body.append($overlay);

  moveNavigation();
  $(window).on('resize', function () {
    (!window.requestAnimationFrame) ? setTimeout(moveNavigation, 300) : window.requestAnimationFrame(moveNavigation);
  });

  $navbarToggle.on('click', function(event) {
    event.preventDefault();

    if ($body.hasClass('nav-is-visible')) {
      closeNav();
    } else {
      openNav();
    }
  });

  $overlay.on('click', function (event) {
    closeNav();
  });

  function openNav() {
    $body.addClass('nav-is-visible');
    $overlay.addClass('is-visible');
    $navbar.addClass('is-open');
    $navbarToggle.addClass('is-active');
  }

  function closeNav() {
    $body.removeClass('nav-is-visible');
    $overlay.removeClass('is-visible');
    $navbar.removeClass('is-open');
    $navbarToggle.removeClass('is-active');
  }

  function isMobile() {
    var e = window,
        a = 'inner';

    if (!('innerWidth' in window )) {
      a = 'client';
      e = document.documentElement || document.body;
    }

    return e[a + 'Width'] <= mobileWidth;
  }

  function moveNavigation() {
    /*var navigation = $('.cd-nav');
    var desktop = checkWindowWidth();
    if (desktop) {
      navigation.detach();
      navigation.insertBefore('.cd-header-buttons');
    } else {
      navigation.detach();
      navigation.insertAfter('.cd-main-content');
    }*/
  }
});